<template>
<div class="shadow-lg rounded-2xl w-40 p-4 bg-white dark:bg-gray-800">
  <div class="flex items-center">
	<div class="flex-1">
	<DarkModeToggle />
	<LanguageToggle/>
	<SiteVisits/>
	</div>
</div>
</div>
</template>
<script>
import DarkModeToggle from './DarkModeToggle.vue'
import LanguageToggle from './LanguageToggle.vue'
import SiteVisits from './SiteVisits.vue'
export default {
components: {
DarkModeToggle,LanguageToggle,SiteVisits
}
	
}
</script>