<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="Logo pt-4"
    width="276"
    height="180"
    viewBox="0 0 452 342"
  >
    <g id="af803f97-2d20-43a4-8e02-89c66c986989" data-name="Layer 2">
      <g id="bc446122-8acb-4a75-9ede-139d8c00538a" data-name="Layer 1">
        <path d="M372.14331,0H488.32422L333.8131,296.51867,261.85459,195.441" />
        <path
          class="b0466337-c533-45e7-a759-adbe5cf03bea"
          d="M93.78782,138.83959,11.46739,0H145.39177l27.85,60.20486h.8192L201.50134,0h121.2282L239.18031,141.2972l95.42621,155.22147H197.81493l-36.85976-70.85376h-.8192L123.6858,296.51867H0Z"
        />
      </g>
    </g>
  </svg>
</template>

<style>
  .Logo {
    animation: 1s appear;
    margin: auto;
  }

  .b0466337-c533-45e7-a759-adbe5cf03bea {
    fill: #fcee21;
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
  }
</style>
