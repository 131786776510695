s<template>
  <div
    class="rounded-full border-2 font-bold text-center mt-11 p-3 dark:text-white"
  >
    {{ count }}
  </div>
</template>
<script>
  import axios from "axios";
  export default {
    data() {
      return {
        count: "",
      };
    },
    methods: {
      async getVisits() {
        await axios
          .get("https://api.fifteenpeas.com/v1/hit/count/xaviervillamuera")
          .then((response) => {
            this.count = response.data;
          });
      },
    },
    created() {
      this.getVisits();
    },
  };
</script>
