<template>
  <div class="shadow-lg rounded-2xl w-36 p-4 bg-white dark:bg-gray-800">
    <div class="flex items-center">
      <p class="text-md text-gray-700 ml-2 dark:text-gray-50">
        {{ title }}
      </p>
    </div>
    <div class="flex flex-col justify-start">
      <p
        class="text-gray-800 text-4xl text-left dark:text-white font-bold my-4"
      >
        {{ main_value }}
      </p>
      <div v-if="gauge" class="relative w-28 h-2 bg-gray-200 rounded"></div>
      <p class="text-md text-gray-700 ml-2 dark:text-gray-50">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: "CardWidget",
    props: {
      title: String,
      subtitle: String,
      main_value: String,
      gauge: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
