<template>
  <div id="techs" class="flex flex-wrap m-2 gap-3">
    <p class="flex-1 text-3xl font-bold dark:text-white">
      {{ $t("message.tech") }}
    </p>
    <CardWidget
      v-for="tech in technologies"
      :key="tech.title"
      class="flex-1"
      :title="tech.title"
      :main_value="tech.main_value"
      :gauge="tech.gauge"
      :style="{
        animation:
          'blur-out 0.4s ' +
          (0.4 + tech.num / 10).toString() +
          's' +
          ' linear both',
      }"
    />
  </div>

  <div class="flex flex-wrap m-1 gap-1 center">
    <DataWidget class="flex-1 slide-left" />
    <MyLogo class="flex-1" />
    <IntroText class="m-3" />
    <CardWidget
      :title="$t('message.mgithub')"
      main_value="52"
      :subtitle="$t('message.repositories')"
      class="flex-1 slide-right"
    />
  </div>
  <div class="flex flex-wrap m-3 gap-1 center">
    <BarChart class="slide-bottom flex-1 m-3 gap-1" />
    <DougChart class="slide-bottom2 flex-1 m-3 gap-1" />
    <SiteLinks class="slide-bottom flex-1 m-3 gap-1" />
    <CardControls class="slide-bottom2 flex-1 m-3 gap-1" />

    <CreditModal />
  </div>
</template>

<script>
  import CardWidget from "./components/CardWidget.vue";
  import MyLogo from "./components/MyLogo.vue";
  import IntroText from "./components/IntroText";
  import DataWidget from "./components/DataWidget";
  import BarChart from "./components/BarChart";
  import DougChart from "./components/DougChart";
  import SiteLinks from "./components/SiteLinks";
  import CardControls from "./components/CardControls";
  import CreditModal from "./components/CreditModal";

  export default {
    name: "App",
    components: {
      CardWidget,
      MyLogo,
      IntroText,
      DataWidget,
      BarChart,
      DougChart,
      SiteLinks,
      CardControls,
      CreditModal,
    },
    data() {
      return {
        counter: 1,
        technologies: [
          {
            num: 1,
            title: "MySql",
            main_value: "SQL",
          },
          {
            num: 2,
            title: "Oracle",
            main_value: "SQL",
          },
          {
            num: 3,
            title: "Sap BO",
            main_value: "BI",
          },
          {
            num: 4,
            title: "SAP DS",
            main_value: "ETL",
          },
          {
            num: 5,
            title: "Language",
            main_value: "PHP",
          },
          {
            num: 6,
            title: "Language",
            main_value: "Python",
          },
          {
            num: 7,
            title: "Language",
            main_value: "Js",
            subtitle: "Javascript",
          },
          {
            num: 8,
            title: "Javascript",
            main_value: "Vue",
            subtitle: "Framework",
          },
          {
            num: 9,
            title: "Vue",
            main_value: "Nuxt",
            subtitle: "Framework",
          },
          {
            num: 10,
            title: "Laravel/Lumen",
            main_value: "PHP",
          },
          {
            num: 11,
            title: "Version",
            main_value: "Git",
          },

          {
            num: 12,
            title: "Directus",
            main_value: "CMS",
          },
          {
            num: 13,
            title: "Tailwind",
            main_value: "CSS",
          },
          {
            num: 14,
            title: "Wordpress",
            main_value: "CMS",
          },
          {
            num: 15,
            title: "Bootstrap",
            main_value: "CSS",
          },
          {
            num: 16,
            title: "Strapi",
            main_value: "CMS",
          },
          {
            num: 17,
            title: "Yii2",
            main_value: "PHP",
          },
        ], // technologies
      };
    },
  };
</script>

<style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  @keyframes blur-out {
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0.01px);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

  .slide-bottom {
    animation: slide-bottom 1s linear both;
  }
  @keyframes slide-bottom {
    0% {
      transform: translateY(100px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .slide-bottom2 {
    animation: slide-bottom2 0.5s linear both;
  }
  @keyframes slide-bottom2 {
    0% {
      transform: translateY(100px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

  .slide-right {
    animation: slide-right 0.7s linear both;
  }
  @keyframes slide-right {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0);
    }
  }

  /* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

  .slide-left {
    animation: slide-left 0.6s linear both;
  }
  @keyframes slide-left {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
