<template>
  <div class="dark:text-white font-bold float-left p-5">
    <p class="mb-4">
      While this site seems simple, it uses a lot of technologies and libraries.
      Here is the list
    </p>
    <ul class="list-disc">
      <li>
        Presentation framework:
        <a href="https://tailwindcss.com" target="blank">Tailwind</a>
      </li>
      <li>
        Modal librairy:
        <a href="https://v3.vue-final-modal.org/" target="blank"
          >Vue Final Modal</a
        >
      </li>
      <li>
        Charts:
        <a href="https://vue-chartjs.org/" target="blank">vue-chartjs</a>
      </li>
      <li>
        Storage and state:
        <a href="https://pinia.vuejs.org/" target="blank">Pinia</a>
      </li>
      <li>
        I18N library:
        <a href="https://vue-i18n.intlify.dev/" target="blank">vue I18N</a>
      </li>
      <li>
        Http requests client library:
        <a href="https://axios-http.com/" target="blank">Axios</a>
      </li>
      <li>
        Frontend language: <a href="https://vuejs.org/" target="blank">VueJs</a>
      </li>
    </ul>
  </div>
</template>
