<template>
<h2 class="font-bold text-2xl dark:text-white">{{ $t("message.language")}}</h2>
  <div class="flex">

  <span v-for="locale in availableLocales"
      :key="locale" class="mt-3">
   <a v-if="locale != $i18n.locale"
      class="dark:text-white flex-1 items-center p-2  transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
      href="#"
      
      @click.prevent.stop="setLocale(locale)"
    >
      {{ locale }}
    </a>
    </span>
  </div>
</template>
<script>
//import { useStore } from '@/stores/general'

export default {
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
    }
  },
  computed: {
    availableLocales() {
      // eslint-disable-next-line indent
        return Object.keys(this.$i18n.messages);
     // return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    }
  }
};
</script>
