import { createApp } from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import { createI18n } from "vue-i18n";
import { vfmPlugin } from "vue-final-modal";
import { createPinia } from "pinia";

const messages = {
  en: {
    message: {
      hello: "hello world",
      intro:
        "I am a fullstack Web Engineer and a Business Intelligence Consultant in Luxemburg",
      tech: "Technologies I use",
      links: "Links",
      music: "Music I listen while coding",
      language: "Language",
      projects: "Projects",
      running: "running right now",
      company: "My unicellular company",
      credits: "Credits",
      mgithub: "My Github profile",
      repositories: "Repositories",
    },
  },
  fr: {
    message: {
      hello: "Bonjour",
      intro:
        "Je suis développeur Web Full Stack et consultant en informatique décisionelle au Luxembourg",
      tech: "Technologies",
      links: "Liens",
      music: "Quand je code",
      language: "Langue",
      projects: "Projets",
      running: "en ligne",
      company: "Ma société unicélullaire",
      credits: "Crédits",
      mgithub: "Mon profile Github",
      repositories: "Dépôts",
    },
  },
  es: {
    message: {
      hello: "Hola",
      intro:
        "Soy un desarrollador Web Full Stack y tambien consultor de Business Intelligence en Luxemburgo",
      tech: "Technologias que utilizo",
      links: "Enlaces",
      music: "Escucho esto programando",
      language: "Idioma",
      projects: "Proyectos",
      running: "funccionando ahora mismo",
      company: "Mi sociedad unicelular",
      credits: "Creditos",
      mgithub: "Mi perfil Github",
      repositories: "Repositorios",
    },
  },
};

const i18n = createI18n({
  locale: "en",
  messages,
});

const app = createApp(App);

app.use(i18n);

app.use(vfmPlugin);

app.use(createPinia());

app.mount("#app");
