<template>
  <vue-final-modal
    :name="null"
    :value="false"
    :ssr="true"
    classes="flex justify-center items-center"
    content-class="relative flex flex-col max-h-full mx-4 p-4 border rounded bg-white  dark:border-gray-800"
    :lock-scroll="true"
    :hide-overlay="false"
    :click-to-close="true"
    :esc-to-close="true"
    :prevent-click="false"
    :attach="false"
    :transition="{
      'enter-active-class': 'transition duration-200 ease-in-out transform',
      'enter-from-class': 'translate-y-full',
      'enter-to-class': 'translate-y-0',
      'leave-active-class': 'transition duration-200 ease-in-out transform',
      'leave-to-class': 'translate-y-full',
      'leave-from-class': 'translate-y-0',
    }"
    overlay-transition="vfm"
    :z-index-auto="true"
    :z-index-base="1000"
    :z-index="false"
    :focus-retain="true"
    :focus-trap="false"
    :fit-parent="false"
    :drag="false"
    drag-selector=""
    :keep-changed-style="false"
    :resize="false"
    :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
    :min-width="300"
    :min-height="200"
    :max-width="Infinity"
    :max-height="Infinity"
    v-model="showModal"
    @click-outside="clickOutside"
    @before-open="beforeOpen"
    @opened="opened"
    @before-close="beforeClose"
    @closed="closed"
    @cancel="showModal = false"
  >
    <CreditText class="p-4" />
  </vue-final-modal>
  <button class="btn-primary slide-left 1.4s" @click="showModal = true">
    {{ $t("message.credits") }}
  </button>
</template>
<script>
  import CreditText from "./CreditText.vue";

  export default {
    components: { CreditText },
    data: () => ({
      showModal: false,
    }),
    methods: {
      clickOutside() {
        //  alert('click-outside')
      },
      beforeOpen() {
        // alert('beforeOpen')
      },
      opened() {
        // alert('opened')
      },
      beforeClose() {
        // alert('beforeClose')
      },
      closed() {
        // alert('closed')
      },
    },
  };
</script>
<style>
  .btn-primary {
    @apply py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg focus:ring-opacity-75;
  }
</style>
