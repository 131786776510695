<template>
<div class="flex-1 text-3xl w-52 font-bold dark:text-white">
  {{ $t("message.intro") }}
  </div>
</template>
<script>
//import {VueTyper} from 'vue-typer'
export default {
	//components:{VueTyper}
}
</script>