<template>
  <div class="shadow-lg rounded-2xl w-42 p-4 bg-white dark:bg-gray-800">
    <h2 class="font-bold text-3xl dark:text-white mb-4">
      {{ $t("message.links") }}
    </h2>
    <div class="flex flex-col gap-2 items-center">
      <a
        class="flex-1 btn-primary"
        href="https://fifteenpeas.com"
        target="blank"
        >{{ $t("message.company") }}</a
      >
      <a
        class="flex-1 btn-primary"
        href="https://github.com/xavsio4"
        target="blank"
        >{{ $t("message.mgithub") }}</a
      >
    </div>
  </div>
</template>
<script>
  //import CreditModal from "./CreditModal.vue";

  export default {
    //components: { CreditModal },
  };
</script>
<style>
  .btn-primary {
    @apply py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg focus:ring-opacity-75;
  }
</style>
